import React from "react";

import { Typography } from "../utils";

import * as styles from "./styles.module.scss";

export default class Stepper extends React.Component {
  render() {
    const { stepNum, stepTitle, totalStep, inverted, className } = this.props;
    let steps = [];
    for (let idx = 1; idx <= totalStep; idx++) {
      let whichClassAmI = styles.past;
      if (idx === stepNum) whichClassAmI = styles.current;
      if (idx > stepNum) whichClassAmI = styles.next;
      steps.push(
        <div
          key={`stepper_${idx}`}
          className={`${styles.step} ${whichClassAmI}`}
        />
      );
    }
    return (
      <div
        className={`${styles.stepper} ${
          inverted ? styles.inverted : ""
        } ${className}`}
      >
        <div className={styles.currentStep}>
          <Typography component="span" variant="h400-heavy">
            {stepNum}
          </Typography>
          <Typography
            className={styles.totalStep}
            component="span"
            variant="h400-heavy"
          >
            /{totalStep}
          </Typography>
        </div>
        <div className={styles.stepTitle}>
          <Typography component="span" variant="h400-medium">
            {stepTitle}
          </Typography>
        </div>
        <div className={styles.steps}>{steps}</div>
      </div>
    );
  }
}
