// extracted by mini-css-extract-plugin
export var checkout = "styles-module--checkout--1jE6A";
export var desktopHeader = "styles-module--desktopHeader--2HpbT";
export var checkoutContent = "styles-module--checkoutContent--3PLia";
export var mainContent = "styles-module--mainContent--vIpvX";
export var currentContent = "styles-module--currentContent--2AVW-";
export var backBtn = "styles-module--backBtn--3m3TA";
export var secured = "styles-module--secured--2fQt4";
export var stepper = "styles-module--stepper--1RjzF";
export var setLogoutModal = "styles-module--setLogoutModal--153uU";
export var icon = "styles-module--icon--yoOjH";
export var logoutModalTitle = "styles-module--logoutModalTitle--3Hf0n";
export var logoutModalDescription = "styles-module--logoutModalDescription--2l6hG";
export var logoutModalCTA = "styles-module--logoutModalCTA--2o0K8";
export var close = "styles-module--close--1xwKt";
export var logout = "styles-module--logout--3VUds";