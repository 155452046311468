import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import axios from "axios";
import { LocaleAsString } from "../../localeEngine";
import Layout from "../../components/layout";
import Stepper from "../../components/stepper";
import SecuredPayBy from "../../components/checkout/securedPayBy";
import Summary from "../../components/checkout/summary";
import { Container, Typography, Button } from "../../components/utils";
import Icon from "../../components/icons";
import LogoutModal from "../../components/session/logoutModal";
import GuestDetails from "./screens/guestDetails";
import OrderDetails from "./screens/orderDetails";
import { EMAIL_REGEX } from "../../components/forms/input/regex";
import { Validations } from "../../components/forms/input/data";
import { getSession, setSession } from "../../utils/localStorage";
import { CONNECT, CONNECT_CLIENT } from "../../utils/definitions";
import { gtmFunction } from "../../utils/gtm";
import * as styles from "./styles.module.scss";

interface CheckoutProps {
  location: any;
}

const FormDefaultState = {
  firstName: "",
  lastName: "",
  email: "",
  prefix: "",
  phone: "",
  addressLine1: "",
  city: "",
  country: "",
  postalCode: "",
  repeatEmail: "",
  password: "",
};

const Checkout = (props: CheckoutProps) => {
  const [currentStep, setCurrentStep] = useState(
    (props.location.state && props.location.state.step) || 1
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  const [privacyPolicies, setPrivacyPolicies] = useState(false);
  const [userIsAlreadyRegistered, setUserIsAlreadyRegistered] = useState(null);
  const [form, setForm] = useState(FormDefaultState);
  const [logoutModal, setLogoutModal] = useState(undefined);
  const [loadingLogout, setLoadingLogout] = useState(undefined);

  const session = getSession();
  if (typeof session === "undefined") {
    return <div></div>;
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setError([]);
    const { customer } = getSession();
    if (customer && Object.keys(customer).length > 0) {
      setUserIsAlreadyRegistered(customer);
    } else {
      setUserIsAlreadyRegistered(null);
    }
  }, [currentStep]);

  useEffect(() => {
    gtmFunction("begin_checkout", session.products);
  }, []);

  const products = session.products;

  const getScreen = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <GuestDetails
              setCurrentStep={setCurrentStep}
              form={form}
              setForm={setForm}
              isLogged={userIsAlreadyRegistered}
              logout={() => setLogoutModal(true)}
            />
            {logoutModal && (
              <LogoutModal
                closeFn={handleClose}
                logout={logout}
                loadingLogout={loadingLogout}
              />
            )}
          </>
        );
      case 2:
        return <OrderDetails setCurrentStep={setCurrentStep} />;
    }
  };

  const handleClose = () => {
    if (loadingLogout) return;
    setLogoutModal(false);
  };

  const logout = () => {
    setLoadingLogout(true);
    axios
      .post(`${CONNECT}/checkout/logout`, {
        client: CONNECT_CLIENT,
        session: getSession().uuid,
      })
      .catch((e) => {
        setLoading(false);
        setLoadingLogout(false);
      })
      .then((response) => {
        if (response && response.data) {
          setSession(response.data);
        } else {
          const logoutSession = getSession();
          logoutSession.customer = {};
          setSession(logoutSession);
        }
        setUserIsAlreadyRegistered(null);
        setForm(FormDefaultState);
        setLoading(false);
        setLoadingLogout(false);
        setLogoutModal(false);
      });
  };

  const getTitle = () => {
    switch (currentStep) {
      case 1:
        return <LocaleAsString id="checkout.guest_details.guest_details" />;
      case 2:
        return <LocaleAsString id="checkout.step_two.title" />;
    }
  };

  const getButtonText = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <Typography component="span" variant="h500-heavy">
              <LocaleAsString id="checkout.cta.step_one" />
            </Typography>
            <Icon icon="ArrowRight" size="Small" />
          </>
        );
      case 2:
        return (
          <>
            <Typography component="span" variant="h500-heavy">
              <LocaleAsString id="checkout.cta.step_two" />
            </Typography>
            <Icon icon="ArrowRight" size="Small" />
          </>
        );
    }
  };

  const updateStep = (nextStep: number) => {
    if (nextStep === 3) {
      navigate("/completed");
    } else if (nextStep <= 0) {
      navigate("/cart");
    } else {
      setCurrentStep(nextStep);
    }
  };

  const setCtaActionByStep = () => {
    if (currentStep === 2) {
      return () => {
        setLoading(true);
        axios
          .post(`${CONNECT}/checkout/payment`, {
            client: CONNECT_CLIENT,
            gatewayId: 1,
            session: getSession().uuid,
          })
          .catch(() => setLoading(false))
          .then((response) => {
            setLoading(false);
            if (response && response.data && response.data.redirectURL) {
              window.location.href = response.data.redirectURL;
            }
            if (response && response.data && Array.isArray(response.data)) {
              const errorsData = response.data;
              setError(errorsData);
            }
          });
      };
    }
    if (currentStep === 1)
      return () => {
        setLoading(true);
        if (userIsAlreadyRegistered) {
          axios
            .post(`${CONNECT}/checkout/customer-info`, {
              client: CONNECT_CLIENT,
              session: getSession().uuid,
              customer: { ...form, id: userIsAlreadyRegistered.id },
            })
            .catch((e) => {
              setLoading(false);
            })
            .then((response) => {
              if (response && response.data && response.data.id) {
                setSession({
                  ...getSession(),
                  timeStamp: new Date().getTime(),
                  customer: response.data.customer,
                });
                updateStep(currentStep + 1);
              }
              setLoading(false);
            });
        } else {
          axios
            .post(`${CONNECT}/checkout/register`, {
              client: CONNECT_CLIENT,
              session: getSession().uuid,
              data: form,
            })
            .catch((e) => {
              setLoading(false);
            })
            .then((response) => {
              if (response && response.data && response.data.id) {
                setSession({
                  ...getSession(),
                  timeStamp: new Date().getTime(),
                  customer: response.data.customer,
                });
                updateStep(currentStep + 1);
              } else if (
                response &&
                response.data &&
                typeof response.data === "string"
              ) {
                const errorData = [];
                errorData.push(
                  <LocaleAsString id="checkout.guest_details.form.error.email_already_in_use" />
                );
                setError(errorData);
              }
              setLoading(false);
            });
        }
      };
  };

  const setSecondaryCtaActionByStep = () => {
    if (currentStep === 1) {
      return () => navigate("/cart");
    }
    if (currentStep === 2) {
      return () => setCurrentStep(1);
    }
  };

  const applyValidationsToForm = () => {
    const {
      firstName,
      lastName,
      email,
      prefix,
      phone,
      addressLine1,
      city,
      postalCode,
      country,
      repeatEmail,
      password,
    } = form;

    const phoneValidation = Validations[prefix] ? Validations[prefix] : true;
    const isPhoneValid =
      phoneValidation === true ? true : phoneValidation.test(phone);

    const postalCodeValidation = Validations[country]
      ? Validations[country]
      : true;
    const isPostalCodeValid =
      postalCodeValidation === true
        ? true
        : postalCodeValidation.test(postalCode);

    const userData = !(
      firstName &&
      lastName &&
      addressLine1 &&
      prefix &&
      phone &&
      isPhoneValid &&
      city &&
      postalCode &&
      isPostalCodeValid &&
      country
    );

    if (!userIsAlreadyRegistered) {
      const userCredentials = !(
        password &&
        EMAIL_REGEX.test(email) &&
        repeatEmail === email
      );
      return !(userData === false && userCredentials === false);
    } else {
      return userData;
    }
  };

  const isSummaryDisabled = () => {
    if (currentStep === 1) {
      return applyValidationsToForm();
    }
    if (currentStep === 2) {
      return !privacyPolicies;
    }
  };

  return (
    <Layout
      metatags={{
        title: LocaleAsString({
          id:
            currentStep === 1
              ? "checkout.guest_details.guest_details"
              : "checkout.step_two.title",
        }),
      }}
      hideMobileFooter={true}
      backgroundColor={"#f4f4f9"}
    >
      <Container
        component="section"
        maxWidth={"lg"}
        className={styles.checkout}
      >
        <div className={styles.desktopHeader}>
          {currentStep !== 1 && (
            <Button
              className={styles.backBtn}
              onClick={() => updateStep(currentStep - 1)}
            >
              <Icon icon="ArrowLeft" size="Small" />
            </Button>
          )}
          <Stepper stepNum={currentStep} stepTitle={getTitle()} totalStep={3} />
        </div>
        <div className={styles.checkoutContent}>
          <div className={styles.secured}>
            <SecuredPayBy backgroundColor="white" />
          </div>
          <div className={styles.mainContent}>
            <Stepper
              className={styles.stepper}
              stepNum={currentStep}
              stepTitle={getTitle()}
              totalStep={3}
            />
            <div className={styles.currentContent}>{getScreen()}</div>
          </div>
        </div>
        <Summary
          hideBackButton={currentStep === 1}
          products={products}
          loading={loading}
          cta={setCtaActionByStep()}
          secondaryCta={setSecondaryCtaActionByStep()}
          text={getButtonText()}
          privacyPolicies={{
            enabled: currentStep === 2,
            value: privacyPolicies,
            setValue: setPrivacyPolicies,
          }}
          disabled={isSummaryDisabled()}
          error={error}
          isTotalVisible={currentStep === 1 ? false : true}
        />
      </Container>
    </Layout>
  );
};

export default Checkout;
