import React from "react";
import Modal from "../../modal";
import { Button, Typography } from "../../utils";
import { LocaleAsString } from "../../../localeEngine";
import Icon from "../../icons";
import * as styles from "./styles.module.scss";

interface LogoutModalProps {
  handleClose: Function;
  logout: Function;
  closeFn: Function;
  loadingLogout: boolean;
}

const LogoutModal = ({ logout, closeFn, loadingLogout }: LogoutModalProps) => {
  return (
    <Modal closeFn={closeFn} isAlert={false} size="small">
      <div className={styles.logoutWrapper}>
        <div className={styles.setLogoutModal}>
          <Icon className={styles.icon} icon="Logout" size="Small" />
          <Typography
            component="p"
            variant="p-heavy"
            className={styles.logoutModalTitle}
          >
            <LocaleAsString id="checkout.modal.logout.title" />
          </Typography>
        </div>
        <div className={styles.logoutModalCTA}>
          <Button
            onClick={() => closeFn()}
            onKeyPress={() => closeFn()}
            className={styles.close}
          >
            <Typography component={"span"} variant={"p-heavy"}>
              <LocaleAsString id="checkout.modal.logout.close" />
            </Typography>
          </Button>
          <Button
            onClick={() => logout()}
            className={styles.logout}
            loading={loadingLogout}
          >
            <Typography component={"span"} variant={"p-heavy"}>
              <LocaleAsString id="checkout.modal.logout.logout" />
            </Typography>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
export default LogoutModal;
