// extracted by mini-css-extract-plugin
export var stepper = "styles-module--stepper--Wdj0L";
export var inverted = "styles-module--inverted--2MkDF";
export var currentStep = "styles-module--currentStep--20PJn";
export var totalStep = "styles-module--totalStep--3n5pZ";
export var stepTitle = "styles-module--stepTitle--rTK-t";
export var steps = "styles-module--steps--1YsWk";
export var step = "styles-module--step--2ilX6";
export var next = "styles-module--next--CVe-s";
export var past = "styles-module--past--3XZjD";
export var current = "styles-module--current--2ZFSo";